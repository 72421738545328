import React, { useEffect } from 'react';
import GLightbox from 'glightbox';

import image1 from '../assets/images/1.jpg';
import image3 from '../assets/images/3.jpg';
import image4 from '../assets/images/4.jpg';
import image5 from '../assets/images/5.jpg';
import image6 from '../assets/images/6.jpg';
import image7 from '../assets/images/7.jpg';
import image8 from '../assets/images/8.jpg';
import image9 from '../assets/images/9.jpg';
import image10 from '../assets/images/10.jpg';
import image11 from '../assets/images/11.jpg';
import image12 from '../assets/images/12.jpg';
import image13 from '../assets/images/13.jpg';
import image14 from '../assets/images/14.jpg';
import image15 from '../assets/images/15.jpg';
import image16 from '../assets/images/16.jpg';

const ImageGallery = () => {
  useEffect(() => {
    const lightbox = GLightbox({
      selector: '.glightbox',
      touchNavigation: true,
      loop: true
    });

    return () => {
      lightbox.destroy();
    };
  }, []);

  // Array of image paths
  const imagePaths = [
    image1, image16, image3, image4, image5, image6, image7, image8, image9, 
    image10, image11, image12, image13, image14, image15
  ];

  return (
    <div className="container">
    <div className="row">
        {imagePaths.map((imagePath, index) => (
        <div key={index} className="col-lg-4 col-md-6 col-12">
            <div className="single-news">
            <div className="image image-wrapper">
                <a href={imagePath} className="glightbox" data-glightbox="image">
                <img className="thumb project-image img-fluid" src={imagePath} alt={`Image ${index + 1}`} />
                </a>
            </div>
            </div>
        </div>
        ))}
    </div>
    </div>
  );
};

export default ImageGallery;
