import React from 'react';
import Navbar from './components/navbar';
// import Sidebar from './components/sidebar';
import Header from './components/header';
import About from './components/about';
import Services from './components/services';
import Projects from './components/projects';
import Contact from './components/contact';
import Map from './components/map.js';
import Footer from './components/footer';
import './assets/css/bootstrap.min.css';
import './assets/css/lineicons.css';
import './assets/css/tiny-slider.css';
import './assets/css/glightbox.min.css';
import './assets/css/styles.css';


import './assets/js/main';
import './assets/js/tiny-slider';
import './assets/js/bootstrap.bundle.min.js'
import ContactForm from './components/contact_form.js';
import Form from './components/form.js';
import TestForm from './components/test.js';

function App() {
  return (
    <div>
      <header>
      <nav>
        <Navbar />
      </nav>
      </header>
      <main>
        <Header />
        <About />
        <Services />
        <Projects />
        {/* <ContactForm /> */}
        {/* <Form /> */}
        <TestForm />
        <Map />
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default App;
