import React from 'react';
import kestrel_icon from '../assets/images/icon.png'

function Services() {
  return (
    <section id="services" className="services-area services-eight">
      <div className="section-title-five">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="content">
                <h6>Services</h6>
                <h2 className="fw-bold">Our Best Services</h2>
                <p>
                  There are many variations of passages of Lorem Ipsum available,
                  but the majority have suffered alteration in some form.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="single-services">
              <div className="service-icon">
              <img className='img-fluid' src={kestrel_icon}/>
              </div>
              <div className="service-content">
                <h4>Steel Construction</h4>
                <p>
                  We specialize in steel buildings, including warehouses, pre-engineered structures, and custom designs. 
                  Our services encompass fabrication, installation, and all aspects of steel structures.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-services">
              <div className="service-icon">
                <img className='img-fluid' src={kestrel_icon}/>
              </div>
              <div className="service-content">
                <h4>General Construction</h4>
                <p>
                  From villas and commercial buildings to hard landscaping, we handle all your construction needs. 
                  Our expertise covers new construction and renovations.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-services">
              <div className="service-icon">
              <img className='img-fluid' src={kestrel_icon}/>
              </div>
              <div className="service-content">
                <h4>Maintenance Works</h4>
                <p>
                  We provide comprehensive maintenance services for existing buildings, including repairs for walls, floors, roofs, 
                  tiles, kitchens, bathrooms, and more. We also handle electrical, plumbing, and mechanical (MEP) system maintenance.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
