import React from 'react';
import logo from '../assets/images/kestrel_logo.png';

const Footer = () => {
  return (
    <>
    <footer className="footer-area footer-eleven">
      <div className="footer-top">
        <div className="container">
          <div className="inner-content">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="footer-widget f-about">
                  <div className="logo">
                    <a href="index.html">
                      <img src={logo} alt="#" className="img-fluid" style={{ maxWidth: '300px' }} />
                    </a>
                  </div>
                  <p>
                    Your partner in Construction & Building Maintenance​
                  </p>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-12">
                <div className="footer-widget f-link">
                  <h5>Quick Links</h5>
                  <ul>
                    <li><a href="#about">About Us</a></li>
                    <li><a href="#services">Services</a></li>
                    <li><a href="#projects">Projects</a></li>
                    <li><a href="#contact">Contact Us</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-12">
                <div className="footer-widget f-link">
                  <h5>Support</h5>
                  <ul>
                    <li><a href="javascript:void(0)">Terms & Conditions</a></li>
                    <li><a href="javascript:void(0)">Privacy Policy</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="footer-widget newsletter">
                  <h5>Subscribe</h5>
                  <p>Subscribe to our newsletter for the latest updates</p>
                  <form action="#" method="get" target="_blank" className="newsletter-form">
                    <input name="EMAIL" placeholder="Email address" required="required" type="email" />
                    <div className="button">
                      <button className="sub-btn">
                        <i className="lni lni-envelope"></i>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="copyright-text d-flex justify-content-center align-items-center pt-5">
        <span>Copyright © 2024 kestrel Construction</span>
      </p>
    </footer>

    <a href="#top" className="scroll-top btn-hover">
        <i className="lni lni-chevron-up"></i>
        </a>
    </>
  );
};

export default Footer;
