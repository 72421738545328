import React, { useState } from 'react';
import Contact from './contact';
import axios from 'axios';

const TestForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });

    const [responseMessage, setResponseMessage] = useState(null);
    const [responseType, setResponseType] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        axios.post('http://localhost/server.php', formData)
            .then(response => {
                setResponseMessage(response.data.message);
                setResponseType('success');
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    subject: '',
                    message: ''
                });
            })
            .catch(error => {
                console.error('Error response:', error.response);
                if (error.response) {
                    setResponseMessage(error.response.data.message || 'An error occurred.');
                    setResponseType('danger');
                } else if (error.request) {
                    setResponseMessage('No response received from server.');
                    setResponseType('danger');
                } else {
                    setResponseMessage('An error occurred.');
                    setResponseType('danger');
                }
            });
    };

    return (
        <div>
            {responseMessage && (
                <div className={`alert alert-${responseType} mt-3`} role="alert">
                    {responseMessage}
                </div>
            )}
            <section id="contact" className="contact-section">
                <div className="container">
                    <div className="row">
                        <Contact />
                        <div className="col-xl-8">
                            <div className="contact-form-wrapper">
                                <div className="row">
                                    <div className="col-xl-10 col-lg-8 mx-auto">
                                        <div className="section-title text-center">
                                            <span> Get in Touch </span>
                                            <h2>Ready to Get Started</h2>
                                            <p>Start Your Project Today (great for construction services)</p>
                                        </div>
                                    </div>
                                </div>
                                <form onSubmit={handleSubmit} className="contact-form">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                placeholder="Name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <input
                                                type="email"
                                                name="email"
                                                id="email"
                                                placeholder="Email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <input
                                                type="text"
                                                name="phone"
                                                id="phone"
                                                placeholder="Phone"
                                                value={formData.phone}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <input
                                                type="text"
                                                name="subject"
                                                id="subject"
                                                placeholder="Subject"
                                                value={formData.subject}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <textarea
                                                name="message"
                                                id="message"
                                                placeholder="Type Message"
                                                rows="5"
                                                value={formData.message}
                                                onChange={handleChange}
                                                required
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="button text-center rounded-buttons">
                                                <button type="submit" className="btn primary-btn rounded-full">
                                                    Send Message
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default TestForm;
