import React, { useState } from 'react';
import logo from '../assets/images/kestrel_logo.png';

const Navbar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleMenuClick = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleCloseClick = () => {
    setIsSidebarOpen(false);
  };

  return (
    <>
      <section className="navbar-area navbar-nine">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav className="navbar navbar-expand-lg justify-content-between flex-nowrap" id="navbar">
                <a className="navbar-brand" href="index.html">
                  <img width="30%" max-height="200px" src={logo} alt="Logo" />
                </a>
             <div className="navbar-btn d-lg-inline-block">
                  <a className="menu-bar" href="#side-menu-left" onClick={handleMenuClick}>
                    <i className="lni lni-menu"></i>
                  </a>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className={`sidebar-left ${isSidebarOpen ? 'open' : ''}`}>
          <div className="sidebar-close">
            <a className="close" href="#close" onClick={handleCloseClick}>
              <i className="lni lni-close"></i>
            </a>
          </div>
          <div className="sidebar-content">
            <div className="sidebar-logo">
              <a href="index.html">
                <img src={logo} className="img-fluid" alt="Logo" />
              </a>
            </div>
            <p className="text-center pt-2">Your partner in Construction & Building Maintenance​</p>
            <div className="sidebar-menu">
              <h5 className="menu-title">Quick Links</h5>
              <ul>
                <li>
                  <a href="#about">About Us</a>
                </li>
                <li>
                  <a href="#services">Services</a>
                </li>
                <li>
                  <a href="#projects">Projects</a>
                </li>
                <li>
                  <a href="#contact">Contact Us</a>
                </li>
              </ul>
            </div>
            <div className="sidebar-social align-items-center justify-content-center">
              <h5 className="social-title">Follow Us On</h5>
              <ul>
                <li>
                  <a href="javascript:void(0)">
                    <i className="lni lni-facebook-filled"></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i className="lni lni-twitter-original"></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i className="lni lni-linkedin-original"></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i className="lni lni-youtube"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Navbar;
