import React from 'react';
import ImageGallery from './project_img';

function Projects() {
  return (
    <div id="projects" className="latest-news-area brand-area section">
      <div className="section-title-five">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="content">
                <h6>Projects</h6>
                <h2 className="fw-bold">Latest Projects</h2>
                <p>
                  Some of our projects from the bucketlist.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    <ImageGallery />
    </div>
  );
}

export default Projects;
