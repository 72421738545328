// Contact.js

import React from 'react';

function Contact() {
  return (
    <div className="col-xl-4">
            <div className="contact-item-wrapper">
              <div className="row">
                <div className="col-12 col-md-6 col-xl-12">
                  <div className="contact-item">
                    <div className="contact-icon">
                      <i className="lni lni-phone"></i>
                    </div>
                    <div className="contact-content">
                      <h4>Phone</h4>
                      <p>+974 7000 2792</p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-12">
                  <div className="contact-item">
                    <div className="contact-icon">
                      <i className="lni lni-map-marker"></i>
                    </div>
                    <div className="contact-content">
                      <h4>Address</h4>
                      <p>Qatar</p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-12">
                  <div className="contact-item">
                    <div className="contact-icon">
                      <i className="lni lni-envelope"></i>
                    </div>
                    <div className="contact-content">
                      <h4>Email</h4>
                      <p>info@kestrelqatar.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  );
}

export default Contact;
