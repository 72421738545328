import React from 'react';

function Header() {
  return (
    <section id="hero-area" className="header-area header-eight d-flex justify-content-center align-items-center">
      <div className="container">
        <div className="row align-items-center">
          <div className="">
            <div className="header-content">
              <h1 style={{ fontSize: '4em', lineHeight: 'normal' }}>Your partner in Construction & Building Maintenance</h1>
              <div className="button d-flex justify-content-center align-items-center">
                <a href="#contact" className="btn primary-btn">Contact Us</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Header;
