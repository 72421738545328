import React from 'react';

function Map(){
    return(
        <section className="map-section map-style-9">
        <div className="map-container">
          <object style={{ border: 0, height: "500px", width: "100%" }}
            data="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d923089.4725306305!2d50.92459202393315!3d25.343879109235367!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45c534ffdce87f%3A0x1cfa88cf812b4032!2sQatar!5e0!3m2!1sen!2snp!4v1715915944272!5m2!1sen!2snp"></object>
        </div>
      </section>
    )
}

export default Map;